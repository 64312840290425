import { BASE_URL } from './../api/ENV';
import axios from 'axios';
function isPhoneAvailable(phone: string) {
  var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  if (!myreg.test(phone)) {
    return false;
  } else {
    return true;
  }
}
function setUserRole(role: string) {
  let maxAge = 48 * 60 * 60;
  document.cookie = `role=${role}; path=/; max-age=${maxAge};`;
}
function getUserRole() {
  const role = document.cookie.split(`; `).find((value: string) => {
    return /^role=/.test(value);
  });
  return role !== undefined ? role.replace('role=', '') : '';
}
function getToken() {
  const tokenItem = document.cookie.split(`; `).find((value) => {
    return /^accessToken=/.test(value);
  });
  return tokenItem !== undefined ? tokenItem.replace('accessToken=', '') : '';
}

function refreshToken() {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/authorize/token/refresh?refreshToken=${getRefreshToken()}`,
    headers: {
      Authorization: getToken(),
    }
  }).then(({ data }: any) => {
    let maxAge = 48 * 60 * 60;
    // document.cookie = `accessToken=${data.accessToken}; max-age=${maxAge}; path=/`;
    document.cookie = `accessToken=${data.accessToken}; max-age=${maxAge}; path=/`;
    document.cookie = `refreshToken=${data.refreshToken}; max-age=${maxAge}; path=/`;
    setUserRole((data.roles ?? [])[0] || getUserRole());
    // let expirationTime = + new Date() + 8 * 60 * 60 * 1000;
    localStorage.setItem('expirationTime', data.expiration);
    return 'success';
  }).catch(() => {
    // document.cookie = `accessToken=; path=/`;
    // document.cookie = `refreshToken=; path=/`;
    return 'error';
  });
}

function getRefreshToken() {
  const tokenItem = document.cookie.split(`; `).find((value) => {
    return /^refreshToken=/.test(value);
  });
  return tokenItem !== undefined ? tokenItem.replace('refreshToken=', '') : '';
}
function getStatusName(status: string) {
  switch (status) {
    case 'CREATED':
      return '材料待审';
    case 'ACCEPTED':
      return '材料通过';
    case 'NO_ACCEPTED':
      return '材料退回';
    case 'RECOMMENDED':
      return '通过推荐';
    case 'NO_RECOMMENDED':
      return '未获推荐';
    case 'SELECTED':
      return '初审通过';
    case 'NO_SELECTED':
      return '初审未通过';
    case 'RE_SELECTED':
      return '复评通过';  
    case 'NO_RE_SELECTED':
      return '复评未通过';  
    case 'VICTORY':
      return '揭榜通过';
    case 'DEFEAT':
      return '揭榜未通过';
  }
}

// 简单的JS下载方案
function downloadFileSimple(url: string | Blob, fileName: string) {
  const anchor = document.createElement('a')
  fileName = fileName || 'download'
  if ('download' in anchor) {
    if (typeof url === "string") {
      anchor.href = url;
    } else {
      anchor.href = URL.createObjectURL(url);
    }
    anchor.setAttribute('download', fileName)
    anchor.className = 'download-js-link'
    anchor.innerHTML = 'downloading...'
    anchor.style.display = 'none'
    document.body.appendChild(anchor)
    setTimeout(function () {
      anchor.click()
      document.body.removeChild(anchor)
    }, 66)
    return true
  }
}

function getDirections(dirs: any) {
  let res = dirs.map((v: any) => v[1])
  return res.flat(Infinity).join(',');
}

function debounce(fn: any, immediate = true, wait = 1000) {
  let timeout:any;
  return function() {
    let ctx = this;
    let args = arguments;
    if(timeout) clearTimeout(timeout);
    if(immediate) {
      let callNow = !timeout;
      // wait秒后清除定时器
      timeout = setTimeout(()=>{timeout = null;}, wait);
      // 先执行函数
      if(callNow) fn.apply(ctx, args)
    }else {
      // wait秒后执行fn
      timeout = setTimeout(()=>{fn.apply(ctx, args)}, wait);
    }
  }
}

function getNavigatePath(role: string) {
  if (role === 'ROLE_USER') {
    return '/proj-demand/me'
  } else if (
    role === 'ROLE_PLATFORM_ADMIN' ||
    role === 'ROLE_PLATFORM_USER'
  ) {
    //平台管理员或超级管理员
    return '/proj-demand/admin/list/1'
  } else if (
    role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
    role === 'ROLE_INSTITUTION_ADMIN' ||
    role === 'ROLE_INSTITUTION_USER'
  ) {
    //初审单位用户或管理员
    return '/proj-mgmt/list/1'
  } else if (role === 'ROLE_EXPERT') {
    return '/proj-review'
  } else if (role === 'ROLE_REPORT') {
    return '/proj-demand/list/1'
  }
}
export { isPhoneAvailable, setUserRole, getUserRole, getToken, refreshToken, getRefreshToken, getStatusName, downloadFileSimple, getDirections, debounce, getNavigatePath };
