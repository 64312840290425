<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { aimdUrlArr, aingName, aimdName } from '@/store/config';

export default defineComponent({
  name: 'App',
  created() {
    if (aimdUrlArr.indexOf(window.location.host) > -1) {
      document.title = aimdName;
    } else {
      document.title = aingName;
    }
  },
});
</script>

<style>
@import url('./style/lib.css');
@import url('./style/el-mod.css');
body {
  font-family: PingFangSC, PingFang SC, Microsoft Yahei, SimHei, sans-serif;
}
/*在谷歌下移除input[number]的上下箭头*/
  .el-input__inner[type="number"]::-webkit-outer-spin-button,.el-input__inner[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
  /*在firefox下移除input[number]的上下箭头*/
  .el-input__inner[type="number"] {
    -moz-appearance: textfield;
  }
  .el-input__inner{
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#app {
  min-width: 1200px;
}
.el-button--primary {
  --el-button-background-color: #0077FF
}
.el-button--success {
    --el-button-font-color: #606266;
    --el-button-background-color: #fff;
    --el-button-border-color: #DDDFE7;
    --el-button-hover-color: #dddfe74f;
    --el-button-active-font-color: #e6e6e6;
    --el-button-active-background-color: #dddfe74f;
    --el-button-active-border-color: #dddfe74f;
}

</style>
