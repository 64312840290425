import ProjIcon from '@/assets/proj-icon.svg';
import ProjIconSel from '@/assets/proj-icon-sel.svg';
import UserIcon from '@/assets/user-icon.svg';
import UserIconSel from '@/assets/user-icon-sel.svg';
import DemandIcon from '@/assets/demand.svg';
import DemandIconSel from '@/assets/demand_active.svg';
import ApplyIcon from '@/assets/apply.svg';
import ApplyIconSel from '@/assets/apply_active.svg';

const menu = [
  {
    name: '项目申报',
    role: ['ROLE_USER'],
    group: 'proj-apls',
    icon: ProjIcon,
    iconSel: ProjIconSel,
    children: [
      {
        name: '申报中心',
        paths: ['/proj-apls/center/:page'],
      },
      {
        name: '我的申报',
        paths: ['/proj-apls/list/:page'],
      },
      {
        name: '草稿箱',
        paths: ['/proj-apls/draft/:page'],
      }
    ],
  },
  {
    name: '需求大厅',
    role: ['ROLE_REPORT'],
    group: 'proj-demand',
    icon: DemandIcon,
    iconSel: DemandIconSel,
    path: '/proj-demand/list/:page',
  },
  {
    name: '我的需求',
    role: ['ROLE_USER'],
    group: 'proj-demand',
    icon: DemandIcon,
    iconSel: DemandIconSel,
    path: '/proj-demand/me',
  },
  {
    name: '对接需求管理',
    role: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'],
    group: 'proj-demand',
    icon: DemandIcon,
    iconSel: DemandIconSel,
    path: '/proj-demand/admin/list/:page',
  },
  {
    name: '我的申报',
    role: ['ROLE_REPORT'],
    group: 'proj-apply',
    icon: ApplyIcon,
    iconSel: ApplyIconSel,
    path: '/proj-apply/list/:page',
  },
  {
    name: '对接列表',
    role: ['ROLE_USER'],
    group: 'proj-apply',
    icon: ApplyIcon,
    iconSel: ApplyIconSel,
    path: '/proj-apply/me/list/:page',
  },
  {
    name: '对接列表',
    role: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'],
    group: 'proj-apply',
    icon: ApplyIcon,
    iconSel: ApplyIconSel,
    path: '/proj-apply/admin/list/:page',
  },
  {
    name: '项目评审',
    role: ['ROLE_EXPERT'],
    group: 'proj-review',
    icon: ProjIcon,
    iconSel: ProjIconSel,
    children: [
      {
        name: '评审列表',
        paths: ['/proj-review'],
      },
    ],
  },
  {
    name: '项目管理',
    role: ['ROLE_INSTITUTION_ADMIN','ROLE_INSTITUTION_ADMIN_LOWER', 'ROLE_INSTITUTION_USER'],
    group: 'proj-mgmt',
    icon: ProjIcon,
    iconSel: ProjIconSel,
    children: [
      {
        name: '项目申报列表',
        paths: ['/proj-mgmt/list/:page'],
        role: ['ROLE_INSTITUTION_ADMIN_LOWER','ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_USER'],
      },
      // {
      //   name: '项目推荐列表',
      //   paths: ['/proj-mgmt/recommend/:page'],
      //   role: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_USER'],
      // },
      // {
      //   name: '项目文件管理',
      //   paths: ['/proj-mgmt/filesManage/:page'],
      //   role: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_USER'],
      // },
    ],
  },
  {
    name: '用户信息',
    group: 'user',
    role: [
      'ROLE_USER',
      'ROLE_EXPERT',
      'ROLE_INSTITUTION_ADMIN_LOWER',
      'ROLE_INSTITUTION_ADMIN',
      'ROLE_INSTITUTION_USER',
      'ROLE_REPORT'
    ],
    icon: UserIcon,
    iconSel: UserIconSel,
    children: [
      {
        name: '基本信息',
        paths: ['/user/info'],
        role: ['ROLE_INSTITUTION_ADMIN_LOWER',
        'ROLE_EXPERT',
        'ROLE_INSTITUTION_ADMIN_LOWER',
        'ROLE_INSTITUTION_ADMIN',
        'ROLE_INSTITUTION_USER',
        'ROLE_REPORT'
      ],
      },
      {
        name: '机构认证',
        paths: ['/accredit'],
        role: ['ROLE_USER', 'ROLE_REPORT'],
      },
      {
        name: '密码修改',
        paths: ['/user/pwd'],
        role: ['ROLE_INSTITUTION_ADMIN_LOWER',
        'ROLE_EXPERT',
        'ROLE_INSTITUTION_ADMIN_LOWER',
        'ROLE_INSTITUTION_ADMIN',
        'ROLE_INSTITUTION_USER',
        'ROLE_REPORT'
      ],
      },
    ],
  },
  {
    name: '用户管理',
    role: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'],
    group: 'admin-user',
    icon: UserIcon,
    iconSel: UserIconSel,
    children: [
      {
        name: '注册用户',
        paths: ['/admin/user/registered/list/:page'],
      },
      {
        name: '评审用户',
        paths: ['/admin/user/region/list/:page'],
      },
      {
        name: '后台用户',
        paths: ['/admin/user/background/list/:page'],
      },
      {
        name: '专家用户',
        paths: ['/admin/user/expert/list/:page'],
      },
    ],
  },
  {
    name: '项目管理',
    role: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'],
    group: 'admin-project',
    icon: ProjIcon,
    iconSel: ProjIconSel,
    children: [
      // {
      //   name: '项目上架',
      //   paths:[ '/admin/project/launch/:page'],
      // },
      {
        name: '项目列表',
        paths: [
          '/admin/project/mgmt/dlt/list/:page',
          '/admin/project/mgmt/recommend/list/:page',
        ],
      },
      {
        name: '评审管理',
        paths: ['/admin/review-mgmt'],
      },
      {
        name: '评分排名',
        paths: ['/admin/project/rank/:page'],
      },
      {
        name: '评分文件',
        paths: ['/admin/project/launch/files'],
      },
      // {
      //   name: '推荐文件',
      //   paths: ['/admin/project/recommend/files'],
      // },
      // {
      //   name: '评审协议',
      //   paths:[ '/admin/project/agreement/:page'],
      // },
    ],
  },
];

export default menu;
