<template>
  <div class="body">
    <!-- <div class="float_l"></div>
    <div class="float_r"></div> -->
    <UnauthHeader />
    <div class="main">
      <div class="title">2024上海市首批医疗大模型应用示范场景需求列表</div>
      <div class="filters" :style="{ transform: `scale(${scaleNum}`, height: `${108 * scaleNum}px` }">
        <div @click="switchCondition(item.code)" :class="['filter', activeTabs.includes(item.code) ? 'active' : '']"
          v-for="(item, index) in navs" :key="index">
          <div :class="['bg', activeTabs.includes(item.code) ? 'active' : '']"
            :style="{ '--bg': `url('/images/demand/${item.bg}A.png')`, '--bg-active': `url('/images/demand/${item.bg}.png')` }">
          </div>
          <div class="numCon">
            <div class="num">{{ item.num }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <div class="tableCard">
        <div class="info">
          经过场景征集、线上评审和复审答辩等环节，围绕五大方向的九个重大应用场景，择优形成了上海市首批医疗大模型应用示范场景名单。现予以公示，面向国内重点企业、高等院校、科研院所等机构征集解决方案，促进供需双方对接。
        </div>
        <div class="table">
          <div class="tr tH">
            <div class="td">序号</div>
            <div class="td">场景名称</div>
            <div class="td">所属类别</div>
            <div class="td">建设单位</div>
            <div class="td"></div>
          </div>
          <div class="tr" v-for="(item, index) in tableData" :key="index" @click="goDetail(item.id)">
            <div class="td">{{ index + 1 }}</div>
            <div class="td">
              <div class="sceneTb">
                <img :src="`/images/demand/${icons[item.type]}.png`">
                <div class="ellipsisText">{{ item.name }}</div>
              </div>
            </div>
            <div class="td">{{ item.typeName }}</div>
            <div class="td">
              <div class="companyN ellipsisText"><img src="/images/demand/annotation.png">{{ item.organization }}</div>
            </div>
            <div class="td">
              <div class="btn" @click.stop="toApply(item.id)">我要对接</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script lang="ts">
import { getDemandList, getDemandStatistic } from '@/api/api';
import UnauthHeader from '@/components/header/UnauthHeader.vue';
import { getNavigatePath, getToken, getUserRole } from '@/utils';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'DemandSummary',
  components: {
    UnauthHeader,
  },
  created() {
    this.getNums();
    this.getList();
    this.cancScale();
  },
  mounted() {
    window.addEventListener('resize', this.cancScale)
  },
  beforeMount() {
    window.removeEventListener('resize', this.cancScale)
  },
  data() {
    return {
      scaleNum: 1,
      tableData: [] as any,
      loading: false,
      activeTabs: ['1'],
      icons: {
        '1': 'hospitalIcon',
        '2': 'health',
        '3': 'teachIcon',
        '4': 'industryIcon',
        '13': 'comprehensive'
      },
      navs: [
        {
          num: '--',
          text: '医疗服务',
          bg: 'teach',
          code: '1'
        },
        {
          num: '--',
          text: '卫生管理',
          bg: 'hospital',
          code: '2'
        },
        {
          num: '--',
          text: '创新研发',
          bg: 'culture',
          code: '3',
        },
        {
          num: '--',
          text: '基础支撑',
          bg: 'allD',
          code: '4',
        },
        {
          num: '--',
          text: '综合示范平台',
          bg: 'industry',
          code: '13',
        },
      ],
      numsObj: {} as any
    }
  },
  watch: {
  },
  methods: {
    getNums() {
      getDemandStatistic().then(({data} : {data: any}) => {
        // this.numsObj = data; medicalServiceNumber
        this.navs[0].num = data.medicalServiceNumber
        this.navs[1].num = data.healthManagementNumber
        this.navs[2].num = data.innovateDevelopmentNumber
        this.navs[3].num = data.basicSupportNumber
        this.navs[4].num = data.comprehensiveDemonstrationPlatformNumber
      })
    },
    cancScale() {
      this.scaleNum = Math.min(window.innerWidth / 1400, 1)
    },
    toApply(id: string) {
      if (getToken()) {
        if (getUserRole() === 'ROLE_REPORT') {
          this.$router.push(`/proj-apply/item/${id}`)
        } else {
          this.$router.push(getNavigatePath(getUserRole()) || '')
        }
      } else {
        this.$router.push('/auth/login')
      }
    },
    goDetail(id: string) {
      window.open(`/demand/detail/${id}`, '_blank')
    },
    switchCondition(code: string) {
      if (code === '') {
        this.activeTabs = ['']
      } else {
        this.activeTabs = [code]
      }
      this.getList(1)
    },
    getList(page: number) {
      this.loading = true;
      getDemandList({
        page: page,
        size: 100,
        types: this.activeTabs.join(',')
      }).then(
        ({ data }: any) => {
          this.tableData = data.result;
          this.loading = false;
          this.tableData.forEach((item: any, index: number) => {
            // @ts-ignore
            item.num = index + 1;
          });
        },
      );
    },
  }

})
</script>

<style scoped>
.table {}

.btn {
  width: 90px;
  line-height: 36px;
  border-radius: 4px;
  border: 1px solid #0077FF;
  text-align: center;
  font-size: 14px;
  color: #0077FF;
}

.companyN img {
  width: 13px;
  margin-right: 10px;
  vertical-align: middle;
}

.tr {
  display: flex;
}

.tH {
  width: 1320px;
  line-height: 56px;
  background: #F8F9FB;
  color: #666666;
  display: flex;
}

.tr:nth-of-type(n+2) {
  height: 90px;
  background: #FFFFFF;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.tr:nth-of-type(n+2):hover {
  background: #FAFBFD;
}

.tr:nth-of-type(n+2):not(:last-of-type) {
  border-bottom: 1px solid #EEF0F7;
}

.tr:nth-of-type(n+2) .td {
  display: table-cell;
  vertical-align: middle;
  padding-right: 20px;
}

.td:nth-of-type(1) {
  width: 108px;
  flex: none;
  padding-left: 20px;
}

.td:nth-of-type(2) {
  width: 410px;
  flex: none;
}

.td:nth-of-type(3) {
  width: 322px;
}

.td:nth-of-type(4) {
  width: 360px;
  flex: none;
}

.td:nth-of-type(5) {
  width: 120px;
  flex: none;
}

.ellipsisText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.body {
  min-width: 1000px;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
  user-select: none;
  position: relative;
}

.main {
  background: url('/images/demand/bg.png') 0 0 / 100% auto no-repeat, #F3F6FD;
  overflow: hidden;
  min-height: calc(100vh - 100px);
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  line-height: 33px;
  margin: 40px 0;
  text-align: center;
}

.filters {
  display: flex;
  max-width: 1400px;
  min-width: 1000px;
  gap: 30px;
  margin: auto;
  transform-origin: left top;
  /* transform: scale(v-bind(scaleNum)); */
}

.filter {
  background: #FFFFFF;
  width: 256px;
  height: 108px;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 22px 30px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  cursor: pointer;
  position: relative;
}



.bg {
  background: var(--bg) top right / 86px 108px no-repeat, #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 20px;
  overflow: hidden;
}

.bg.active {
  background: var(--bg-active) top right / 86px 108px no-repeat;
}

.filter.active::before {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  right: 0;
  height: 119.27px;
  background: url('/images/demand/activeBg.png') 0 0 / 100% 100%;
}

.filter.active .num,
.filter.active {
  color: #FFFFFF;
}

.numCon {
  position: relative;
  z-index: 2;
}

.num {
  font-size: 32px;
  font-family: DINAlternate, DINAlternate;
  font-weight: bold;
  color: #222222;
  line-height: 38px;
  margin-bottom: 4px;
}

.customTable table {
  border: none;
}

.tableCard {
  width: 100%;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 40px;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  max-width: 1400px;
  min-width: 1000px;
  margin: 19px auto 0;
  overflow-x: auto;
}

.info {
  margin-bottom: 20px;
}
.sceneTb {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}
.sceneTb img {
  width: 44px;
  height: 44px;
  flex: none;
  margin-right: 16px;
  vertical-align: middle;
}

.sceneTb div {
  /* flex: 1; */
  width: calc(100% - 60px);
}

.float_l {
  position: absolute;
  left: 0px;
  top: 961px;
  width: 230px;
  height: 264px;
  /* background: url('/images/first-page/float_l.webp') 0 0 / cover; */
  pointer-events: none;
}

.float_r {
  position: absolute;
  right: 0px;
  top: 1821px;
  width: 190px;
  height: 190px;
  /* background: url('/images/first-page/float_r.webp') 0 0 / cover; */
  pointer-events: none;
}

.el-button:not(.el-button--text) {
  padding: 10px 35px;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  color: #000000;
  font-weight: normal;
}</style>