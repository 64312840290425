// TODO:
export const download_files = {
  sign_up_file: "https://minio.3incloud.com/dhrp/关于印发《人工智能医疗器械产业创新重点任务揭榜工作方案》的通知_0826.doc",
  project_template_file: "https://ycd-resource.3incloud.com/cds/智能体育典型案例申报书.docx",
  operation_manual_company: 'https://ycd-resource.3incloud.com/cds/智能体育典型案例注册管理系统- 使用手册_企业用户.pdf',
  operation_manual_competent_unit: 'https://ycd-resource.3incloud.com/cds/智能体育典型案例注册管理系统- 使用手册_主管单位用户.pdf'
};
export const productStatus = {
  'NO_ACCEPTED': '材料退回',
  'ACCEPTED': '材料通过',
  'CREATED': '材料待审'
}
// 医疗器械
export const aimdUrlArr = [
  'localhost:3000',
  '10.33.131.123:3000',
  'isd.3incloud.com',
  'aimd.3incloud.com',
  'aimd.caict.ac.cn'
]
// 新一代人工智能
export const aingUrlArr = [
  'localhost:3001',
  'aing.3incloud.com'
]

export const aimdName = '医疗大模型应用示范场景申报系统'
export const aingName = '医疗大模型应用示范场景申报系统'