import { createApp } from 'vue';
import router from './router/router';
import store from './store/store';


import 'normalize.css';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';
import App from './App.vue';
// import Sentry from './../sentry.js'

const app = createApp(App);
// new Sentry().install(app, router);
app.use(router)
  .use(store)
  .use(ElementPlus, { locale: zhCn })
  .mount('#app');