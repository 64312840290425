<template>
  <div class="body">
    <UnauthHeader />
    <!-- banner部分 -->
    <div class="topBanner">
      <div class="projectCenter">
        <div class="bannerTitle">医疗大模型应用示范场景“揭榜挂帅”申报</div>
        <div class="toSignBtn" @click="jump"><div class="content">立即报名</div><img src="../../assets/first-page/goJump.png" class="goJump" alt=""/><img
            src="../../assets/first-page/goJump.png" class="go2" alt=""/></div>
      </div>
    </div>
    <!-- 项目背景 -->
    <div class="backgroundIdea">
      <div class="projectInfo">
        <!--      <div class="projectLeft">-->
        <!--       <img src="../../assets/first-page/ty1.png" alt="" class="topHalf">-->
        <!--      </div>-->
        <div class="projectCenter">
          <div class="proLeft">
            <div class="proNameOne">项目背景</div>
            <img class="title" src="@/assets/first-page/title.png" alt="">

            <div class="proMesContent">
              为进一步贯彻落实《新一代人工智能发展规划》（国发〔2017〕35号）《关于加快场景创新以人工智能高水平应用促进经济高质量发展的指导意见》（国科发规〔2022〕199号）《上海市推动人工智能大模型创新发展若干措施（2023-2025年）》（沪经信智〔2023〕608号）要求，重点支持在生物医药等领域构建人工智能大模型示范应用场景，打造标杆性大模型场景，上海市经信委、上海市卫健委联合开展首批医疗大模型应用示范场景征集及供需对接工作。
            </div>
            <div class="proMes2">
              现为形成良好工作衔接，保持医疗大模型创新工作持续化、常态化，加快医疗大模型新赛道布局，推动更多新场景加快落地，引导医疗大模型产业健康发展，特形成此2024上海市医疗大模型应用示范场景征集工作方案。
            </div>

            <div class="infoLink-first" @click="downloadFile" style="cursor: pointer;">
              <img src="@/assets/first-page/file.svg" alt="" >
              关于组织开展2024本市医疗大模型应用示范场景征集的通知
              <img src="@/assets/first-page/loadimg.png" alt="" style="width:12px;height:12px;margin-left: 10px">
            </div>
          </div>
          <img class="proRight" src="../../assets/first-page/projectBackground.webp" alt=""/>
        </div>
      </div>
      <!-- 思路 -->
      <div class="questionInform imgWrapper">
        <div class="projectCenter">
          <div class="proNameSecond" style="text-align: center;">工作思路</div>
          <img class="title" src="@/assets/first-page/title.png" alt="" style="text-align: center">

          <div class="questionUl">
            <div class="questionLi">
              <!-- 图片1/2/3-->
              <div class="questionMes">
                <div class="questionIco">
                  <img src="../../assets/first-page/sl1.png" alt=""/>
                </div>
                <div class="qmName">聚焦四大方向，八大重点场景</div>
                <div class="qmTxt">
                  聚焦医疗服务、卫生管理、创新研发、基础支撑四大方向，诊疗服务、患者服务、中医诊疗、医院管理、疾控监测、临床科研、药物研发、医疗语料库八大重点场景。
                </div>
              </div>
            </div>
            <div class="questionLi">
              <!-- 图片1/2 -->
              <div class="questionMes">
                <div class="questionIco">
                  <img src="../../assets/first-page/sl2.png" alt=""/>
                </div>
                <div class="qmName">完成全流程工作</div>
                <div class="qmTxt">
                  完成应用场景征集及供需对接全流程工作，包括场景征集、厘清需求、供需对接等工作环节。
                </div>
              </div>
            </div>
            <div class="questionLi">
              <div class="questionIco">
                <img src="../../assets/first-page/sl3.png" alt=""/>
              </div>
              <div class="questionMes">
                <div class="qmName">重点关注供需对接</div>
                <div class="qmTxt">
                  重点关注供需对接，围绕主题方向举办多场专题供需对接会，邀请同领域、高层级的专家共同参与，助力产业各方精准匹配供需资源，实现高效合作。
                </div>
              </div>
            </div>
            <div class="questionLi">
              <div class="questionIco">
                <img src="../../assets/first-page/sl4.png" alt=""/>
              </div>
              <div class="questionMes">
                <div class="qmName">更加聚焦多元互通</div>
                <div class="qmTxt">
                  更加聚焦多元互通，推动场景间沟通协作、资源协同与数据共享，促进多向导流，以形成智能化、多元化、个性化的医疗大模型标杆示范。
                </div>
              </div>
            </div>
          </div>
        </div>
        <img class="imgLeft" src="../../assets/first-page/roundLeft.png" alt="">
        <img class="imgRight" src="../../assets/first-page/roundRight.webp" alt="">
      </div>
    </div>
        <!--要素支持-->
        <div class="factorsupport">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;">配套支持</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">
        <div class="supportcontent">
          <div class="supportLeft">
            <div class="supportleftTop">
              <div class="supportTitle">加强组织保障</div>
              <div class="supportBody">广泛动员各类主体积极参与，在后续申报中给予相关支持和跟踪服务。鼓励各区对进入应用示范场景的属地企业给予相关保障服务，支持打造一批医疗大模型应用示范场景示范标杆。 </div>
            </div>
            <div class="supportleftBottom">
              <div class="supportTitle">强化政策支持</div>
              <div class="supportBody">各行业主管部门将发挥产业高质量发展等专项资金作用，对各行业符合条件的场景项目予以支持；对纳入应用示范场景解决方案的创新产品，优先推荐纳入《上海市创新产品推荐目录》 </div>
            </div>

          </div>
          <div class="middle">
            <img src="@/assets/first-page/support1.png" alt="" />
          </div>
          <div class="supportRight">
            <div class="supportrightTop">
              <div class="supportTitle">开展宣传推广</div>
              <div class="supportBody">市经信委和市卫健委将会同各行业主管部门开展宣传推广，依托官方媒体平台，支持入选应用示范场景的企业扩大品牌知名度与场景推广。 </div>
            </div>
            <div class="supportrightBottom">
              <div class="supportTitle">组织供需对接</div>
              <div class="supportBody">各行业主管部门将发挥指导作用，依托专业化力量，聚焦医疗领域，组织主题沙龙、专场论坛或需求路演等活动，推动需求方与解决方案提供商进行充分沟通对接，精准匹配供需资源。</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--    征集范围/重点方向-->
    <div class="scale">
      <div class="projectCenter">
        <div class="proName3" style="text-align: center;">征集范围+重点方向</div>
        <div style="text-align: center;">
          <img class="title" src="@/assets/first-page/title.png" alt="">
        </div>
        <div class="scaleContent">
          <div class="scaleLeft">
            <div class="leftTop" :class="isExpanded ? 'expand' : ''">
              <div class="leftTop-img">
                <img src="@/assets/first-page/lefttop.png" alt="">
              </div>
              <div class="leftTop-content">
                <div class="leftTopTitle">医疗服务</div>
                <div class="leftTopContent">
                  <div class="contentFirst">
                    <div class="list">
                      <div class="list-content">01</div>
                    </div>
                    <div class="cnt">
                      <div class="sub-title">大模型+诊疗服务</div>
                      <div class="main-content">支持打造面向诊疗服务的大模型创新应用场景，形成覆盖全身多部位、多病种、多模态数据的全流程智慧辅助诊疗服务平台，极大提升诊疗精准度、效率和服务质量。</div>
                    </div>
                  </div>
                  <div class="contentFirst">
                    <div class="list">
                      <div class="list-content">02</div>
                    </div>
                    <div class="cnt">
                      <div class="sub-title">大模型+患者服务</div>
                      <div class="main-content">支持打造面向患者的智能化交互应用场景，构建涵盖智能问诊、精准用药指导、慢病管理、患者随访等多元化应用的大模型超级医生平台，全面提升患者体验和满意度。</div>
                    </div>
                  </div>
                  <div class="contentFirst">
                    <div class="list">
                      <div class="list-content">03</div>
                    </div>

                    <div class="cnt">
                      <div class="sub-title">大模型+中医诊疗</div>
                      <div class="main-content1">
                        支持打造面向中医的诊疗养一体化应用场景，构建基于名老中医临床诊疗数据、中医典籍、中医文献数据到内外妇儿等全学科知识图谱的中医智能辅助诊疗平台。
                      </div>
                      <div class="tag" @click="extendMore">
                        {{ isExpanded ? '收起' : '展开更多' }}
                        <img
                            class="thumbnail_25"
                            referrerpolicy="no-referrer"
                            :src="currentImage"
                            @mouseover="changeImage"
                            @mouseout="restoreImage"
                            alt=""/>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!--            左边底部区域-->
            <div class="leftBottom">
              <div class="leftBottomContent">
                <div class="leftBottom-img">
                  <img src="@/assets/first-page/righttop2.png" alt="">
                </div>
                <div class="leftBottomTitle">基础支撑</div>
                <div class="leftBottomContent">
                  <div class="supportcontent">
                    <div class="leftBottom-list">
                      <div class="list-content">01</div>
                    </div>
                    <div class="leftBottom-cnt">
                      <div class="bottomless-title">大模型+医疗语料库</div>
                      <div class="bottommain-content">支持打造面向大模型的动态流通医疗数据资源，融合多语种医疗语料文本、医学研究文献、中文医疗术语、全病种医学知识库等高质量医疗语料资源，语料库在规模和覆盖范围上达到全球领先水平。</div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div class="scaleRight">
            <div class="rightTop">
              <div class="rightTop-img">
                <img src="@/assets/first-page/leftbottom2.png" alt="">
              </div>
              <div class="leftBottomTitle">卫生管理</div>
              <div class="leftBottomContent">
                <div class="supportcontent">
                  <div class="leftBottom-list">
                    <div class="list-content">01</div>
                  </div>
                  <div class="rightTop-cnt">
                    <div class="bottomless-title">大模型+医院管理</div>
                    <div class="bottommain-content">支持打造面向医院的智能管理决策AI原生应用场景，整合智能医院报表、智慧病房管理、重大事件管控、智能药物管理等功能，优化医院资源配置，全方位提高运营效率和风险防控能力。</div>
                  </div>
                  <div class="leftBottom-list">
                    <div class="list-content">02</div>
                  </div>
                  <div class="leftBottom-cnt">
                    <div class="bottomless-title">大模型+疾控监测</div>
                    <div class="bottommain-content">支持打造面向疾病防控及监测的智慧化应急响应和决策平台应用场景，建立涵盖动态疾病预测、社交媒体监测与干预、卫生资源实时调配等场景的智能疾控监测服务平台。</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="rightBottom">
              <div class="rightBottom-img">
                <img src="@/assets/first-page/rightbottom.png" alt="">
              </div>
              <div class="leftBottomTitle">创新研发</div>
              <div class="leftBottomContent">
                <div class="supportcontent">
                  <div class="leftBottom-list">
                    <div class="list-content">01</div>
                  </div>
                  <div class="rightTop-cnt">
                    <div class="bottomless-title">大模型+临床科研</div>
                    <div class="bottommain-content">支持打造面向临床科研的创新应用场景，形成集多中心临床、智能病例入组、智能数据分析等应用于一体的科研服务体系，降低科研成本，显著提升科研成果质量和转化效率。</div>
                  </div>
                  <div class="leftBottom-list">
                    <div class="list-content">02</div>
                  </div>
                  <div class="leftBottom-cnt">
                    <div class="bottomless-title">大模型+药物研发</div>
                    <div class="bottommain-content">支持打造面向药企的一站式智能研发应用场景，建立涵盖AI药物虚拟筛选、生成式AI药物设计、药物靶点发现、创新分子优化等环节的药物研发大模型，全面提高药物研发效率和成功率。</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--    征集要求-->
    <div class="recruit">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;">征集要求</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">
        <div class="recruitBody">
          <div class="recruitLeft">
            <img src="@/assets/first-page/recruitBg.webp" alt="">
          </div>
          <div class="recruitRight">
            <div class="recruitLi">
              <div class="recruiticon">
                <img src="@/assets/first-page/recruit1.png" alt="">
              </div>
              <div class="recruitContent">
                <div class="recruitTitle">征集场景对标</div>
                <div class="recruitmain-body">集场景应对标前沿，在医疗大模型领域内具有引领带动作用，在本市乃至国内能够形成应用示范效应，具备可复制性、可推广性。</div>
              </div>
            </div>
            <div class="recruitLi">
              <div class="recruiticon">
                <img src="@/assets/first-page/recruit2.png" alt="">
              </div>
              <div class="recruitContent">
                <div class="recruitTitle2">征集应用建设方向</div>
                <div class="recruitmain-body">各应用示范场景建设方向需符合医疗大模型场景要求，建设内容需包含大模型算法、框架等基础原创性技术。</div>
              </div>
            </div>
            <div class="recruitLi">
              <div class="recruiticon">
                <img src="@/assets/first-page/recruit3.png" alt="">
              </div>
              <div class="recruitContent">
                <div class="recruitTitle">征集态势</div>
                <div class="recruitmain-body">征集场景应形成大企业带动小企业协同发展的良好态势，推动实现技术融合、产业融合、数据融合以及跨层级、跨行业的协同联动。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 申报条件 -->
    <div class="applyCondition">
      <div class="projectCenter">
        <div class="applyproName" style="text-align: left;">申报条件</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">
        <div class="applyContent">
          <div class="applyList">
            <div class="applyicon">
              <img src="@/assets/first-page/applyimg.png" alt="">
            </div>
            <div class="applymain-body">
              申报主体为在上海市内注册、具备独立法人资格的重点企业、高等院校、科研院所、产业园区等机构单位。
            </div>
          </div>
          <div class="applyList">
            <div class="applyicon">
              <img src="@/assets/first-page/applyimg.png" alt="">
            </div>
            <div class="applymain-body">
              申报主体应具备支撑应用示范场景建设和运营的必要基础，在质量、安全、信誉和社会责任等方面无不良记录。
            </div>
          </div>
          <div class="applyList">
            <div class="applyicon">
              <img src="@/assets/first-page/applyimg.png" alt="">
            </div>
            <div class="applymain-body">
              许以联合体方式参与申报，牵头单位为1家，联合单位不超过5家。
            </div>
          </div>
          <div class="applyList">
            <div class="applyicon">
              <img src="@/assets/first-page/applyimg.png" alt="">
            </div>

            <div class="applymain-body">
              各单位可申报多个场景，若需要申报多个场景，需分多次单独完成申报。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--申报方式/步骤 -->
    <div class="applyway">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;">申报方式/步骤</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">
        <div class="applywayBody">
          <img src="@/assets/first-page/apply.png" alt="" />
          <div class="applycontainer">
            <div class="applycontent">
              <div class="applytitle">注册/登录</div>
              <div class="applydesc">各申报主体于2024年7月15日之前登录“上海市医疗大模型应用示范场景征集申报信息管理平台”（网址：https://llm.3incloud.com）进行申报</div>
            </div>
            <div class="applycontent">
              <div class="applytitle">上传申报材料</div>
              <div class="applydesc">完成注册后上传申报所需材料（需要场景牵头单位进行注册上传）</div>
            </div>
            <div class="applycontent">
              <div class="applytitle">遴选</div>
              <div class="applydesc">市经济信息化委、市卫生健康委对征集到的场景需求进行遴选</div>
            </div>
            <div class="applycontent">
              <div class="applytitle">择优打造标杆</div>
              <div class="applydesc">择优作为2024上海医疗大模型应用示范场景征集项目，在此基础上推动打造应用示范场景示范标杆</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    联系方式-->
    <div class="contactway">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;">联系方式</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">
        <div class="contactmainContent">
          <div class="contactTitle">各部门咨询联系人
            <div class="line" ></div>
          </div>

          <div class="contactContent">

            <div class="contactBody">
              <div class="contactdepart">市经济和信息化委员会</div>
              <div class="contactli">
                <div class="contactnameimg">
                  <img src="@/assets/first-page/contactname2.png" alt="">
                </div>
                <div class="contactphone">商春秋</div>
              </div>

              <div class="contactli">
                <div class="contactphoneimg">
                  <img src="@/assets/first-page/contactphone.png" alt="">
                </div>
                <div class="contactphone">15026931223</div>
              </div>
            </div>
            <div class="contactBody">
              <div class="contactdepart">市卫生健康委员会</div>
              <div class="contactli">
                <div class="contactnameimg">
                  <img src="@/assets/first-page/contactname2.png" alt="">
                </div>
                <div class="contactphone">商春秋</div>
              </div>

              <div class="contactli">
                <div class="contactphoneimg">
                  <img src="@/assets/first-page/contactphone.png" alt="">
                </div>
                <div class="contactphone">15026931223</div>
              </div>
            </div>
            <div class="contactBody">
              <div class="contactdepart">中国信通院上海工创中心</div>
              <div class="contactli">
                <div class="contactnameimg">
                  <img src="@/assets/first-page/contactname2.png" alt="">
                </div>
                <div class="contactphone">商春秋</div>
              </div>

              <div class="contactli">
                <div class="contactphoneimg">
                  <img src="@/assets/first-page/contactphone.png" alt="">
                </div>
                <div class="contactphone">15026931223</div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <div>
    </div>
    <div class="footerModule">Copyright © 2018-2022 中国信息通信研究院 版权所有  |  <a href="https://beian.miit.gov.cn/"
                                                                                       style="color:rgba(255,255,255,0.6);text-decoration: none;">沪ICP备17037709号-3</a></div>
  </div>
</template>
<script lang="ts">
import { defineComponent,ref } from 'vue';
import UnauthHeader from '@/components/header/UnauthHeader.vue';
import { downloadFileSimple, getToken, getUserRole } from '@/utils';
import { download_files } from '@/store/config';
const content = ref('支持打造面向中医的诊疗养一体化应用场景，构建基于名老中医临床诊疗数')
export default defineComponent({
  name: 'ProjectSignUp',
  components: {
    UnauthHeader,
  },
  data() {
    return {
      role: '',
      token: '',
      isEnd: false,
      download_url: download_files.sign_up_file,
      isExpanded: false,
      collapsedHeight: 78, // 默认高度
      expandedHeight: 98 ,// 展开后的高度
      content: '支持打造面向中医的诊疗养一体化应用场景，构建基于名老中医临床诊疗数',
      maxLength: 70, // 初始显示的最大字符数
      currentImage: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3c412f14791de40b77178cae926f1c47d4dbefd6b8a40e5e2cc5f4040cacc94c',
      originalImage: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3c412f14791de40b77178cae926f1c47d4dbefd6b8a40e5e2cc5f4040cacc94c',
      newImage: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8c6803635565fc81db27200ce685053c0a04adc1dd2910c2afb8b70a7c627c80', // 替换的新图片链接
    };
  },
  methods: {
    openNewPage() {
      window.open('https://wap.miit.gov.cn/jgsj/txs/wjfb/art/2023/art_fdb60446250241418fc20e85b35b5818.html', '_blank')
    },
    extendMore() {
      // const content = '支持打造面向中医的诊疗养一体化应用场景，构建基于名老中医临床诊疗数据、中医典籍、中医文献数据到内外妇儿等全学科知识图谱的中医智能辅助诊疗平台。'
      // console.log(content)
      // document.querySelector('.main-content1').innerHTML = content
      this.isExpanded = !this.isExpanded;
    },
    changeImage() {
      this.currentImage = this.newImage;
    },
    restoreImage() {
      this.currentImage = this.originalImage;
    },
    downloadFile() {
      // 关于组织开展2023年“宽带网络+健康乡村”应用试点项目申报工作的通知.docx notice.docx
      downloadFileSimple('/notice.docx', '关于组织开展2024年本市医疗大模型应用示范场景征集的通知.pdf')
      // window.open('https://inos.3incloud.com/public/xcjk/%E5%85%B3%E4%BA%8E%E7%BB%84%E7%BB%87%E5%BC%80%E5%B1%952023%E5%B9%B4%E2%80%9C%E5%AE%BD%E5%B8%A6%E7%BD%91%E7%BB%9C+%E5%81%A5%E5%BA%B7%E4%B9%A1%E6%9D%91%E2%80%9D%E5%BA%94%E7%94%A8%E8%AF%95%E7%82%B9%E9%A1%B9%E7%9B%AE%E7%94%B3%E6%8A%A5%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.docx');
    },
    jump() {
      if (this.token) {
        if (this.role === 'ROLE_USER') {
          this.$router.push('/proj-apls/center/1');
        } else if (
            this.role === 'ROLE_PLATFORM_ADMIN' ||
            this.role === 'ROLE_PLATFORM_USER'
        ) {
          //平台管理员或超级管理员
          this.$router.push('/admin/user/registered/list/1');
        } else if (
            this.role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
            this.role === 'ROLE_INSTITUTION_ADMIN' ||
            this.role === 'ROLE_INSTITUTION_USER'
        ) {
          //初审单位用户或管理员
          this.$router.push('/proj-mgmt/list/1');
        } else if (this.role === 'ROLE_EXPERT') {
          this.$router.push('/proj-review');
        }
      } else {
        this.$router.push(`/auth/login`);
      }
    },
  },
  created() {
    this.token = getToken();
    this.role = getUserRole();
    if (1639583999000 > new Date().getTime()) {
      this.isEnd = false;
    } else {
      this.isEnd = true;
    }
  },
});
</script>

<style scoped>
.backgroundIdea {
  width:100%;
  height:1139px;
}
.applyList {
  display: flex;
  margin-bottom: 24px;
  img {
    width:18px;
    height:14px;
    margin-top: 4px;
  }
}
.applyicon {
  /* margin-top: 4px; */
}
.applymain-body {
  margin-left: 12px;

}
.applymain-body {
  width: 1200px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}

.applyproName {
  width: 128px;
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 32px;
  color: #FFFFFF;
  line-height: 44px;
  text-align: left;
  font-style: normal;
  margin-top: 60px;
  margin-bottom: 24px;
}
.applyCondition .titleOne {
  margin-bottom: 60px;
}
.applyicon img {
  margin-bottom: 4px !important;
}
.line {

  padding-top: 8px;
  height: 8px;
  background: rgba(0,119,255,0.12);
  position: absolute;
  /* top: 100%;  */
  bottom: 0;/* 将线移动到文字底部 */
  width:100%
}
.recruitTitle2 {
  width: 144px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}
.title {
  margin: 0 auto;
  display: block;
}
.body {
  overflow-x: hidden; /* 禁止水平滚动 */
}

.bannerTitle {
  text-align: left;
  line-height: 66px;
  height: 66px;
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 50px;
  color: #FFFFFF;
  letter-spacing: 5px;
  font-style: normal;
  margin-bottom: 40px;
}
.projectLeft {
  position: relative;
  margin-top: 340px;
  width: 194px;
  height: 157px; /* 设置容器的高度为图片高度的一半 */
  overflow: hidden; /* 隐藏超出容器的部分 */
}
.topHalf {
  position: absolute; /* 绝对定位 */
  top: 0; /* 图片顶部与容器顶部对齐 */
  left: 0; /* 图片左边与容器左边对齐 */
  width: 100%; /* 图片宽度和容器宽度相同 */
}
.bottomHalf {
  position: absolute; /* 绝对定位 */
  bottom: 0; /* 图片底部与容器底部对齐 */
  left: 0; /* 图片左边与容器左边对齐 */
  width: 100%; /* 图片宽度和容器宽度相同 */
}
.questionLeft {
  width: 194px; /* 设置容器的宽度 */
  height: 157px; /* 设置容器的高度 */
  overflow: hidden; /* 超出部分隐藏 */
  position: relative; /* 相对定位，为了使绝对定位的元素参照它定位 */
}

.questionLeft {
  width: 194px; /* 设置容器的宽度 */
  height: 157px; /* 设置容器的高度 */
  overflow: hidden; /* 超出部分隐藏 */
  position: relative; /* 相对定位，为了使绝对定位的元素参照它定位 */
}
.toSignBtn {
  display: flex;
  width: 160px;
  height: 50px;
  background: linear-gradient( 270deg, #D9EFFF 0%, #FFFFFF 100%);
  border-radius: 25px;

}

.toSignBtn img {
  margin-left: 10px;
}
.proLeft {
  width: 100%;
  height: 572px;
  float: left;
}
.proName {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  padding: 40px 0 18px 0;

}
.proNameOne {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  //padding: 40px 0 18px 0;
  margin-top: 60px;

}
.proNameSecond {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  //padding: 40px 0 18px 0;
  margin-top: 47px;
  margin-bottom: 18px;

}
.proName3 {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  margin-top: 60px;
  padding: 20px 0 18px 0;

}
.proMesContent {
  width: 660px;
  height: 140px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  padding-top: 30px;
  box-sizing:content-box;
}
.proMes2 {
  width: 660px;
  height: 84px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  box-sizing: content-box;
}
.proRight {
  position: absolute;
  right: 0;
  top: 0;
  width: 534px;
  height: 492px;
  display: block;
}
.projectCenter {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.body {
  min-width: 1300px;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
  user-select: none;
}
.questionInform img:first-of-type {
  margin: 0 auto 60px;
}

.questionInform img.leftImg {
  width: 480px;
  margin-left: 0;
  margin-right: 0px;
  margin-bottom: 0px;
}
.questionInform {
  display: flex;
  width: 100%;
  height:567px;
  overflow: hidden;
  padding-bottom: 20px;
  background: #FAFBFF;
}
.infoListCon {
  display: flex;
  justify-content: space-between;
}
.questionInform .infoList {
  width: 676px;
}
.telIco::before{
  content:"";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("../../assets/first-page/telIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top:2px;
}
.main-content1 {
  width: 464px;
  height:30px;
  overflow:hidden;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  font-style: normal;
  /* margin-bottom: 20px; */
  margin-left: 10px;
}
.scale {
  margin:auto;
  height:1219px;
  background-image:url("../../assets/first-page/scalebg002.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.tag {
  margin-left: 200px;
  margin-top: 30px;
  color: #999; /* 展开提示文字颜色 */
  cursor: pointer; /* 鼠标指针显示为手型 */
  margin-bottom:36px;
}
.tag:hover {
  color: rgba(0, 119, 255, 1);
  cursor: pointer; /* 这会将鼠标指针变为手型，表明该元素可点击 */
}
.thumbnail_25:hover {
  src: "../../assets/first-page/scalebg002.webp";

}
.main-content {
  overflow: hidden;
  transition: max-height 0.3s ease; /* 添加过渡效果 */
}
.contactnameimg {
  margin-right: 8px;
}
.contactli {
  display: flex;
  margin-bottom: 10px;
}
.contactphoneimg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.contactdepart {
  width: 176px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 18px;
}
.contactname {
  width: 42px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 10px;
}
.contactphone {
  width: 42px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-left: 2px;
}
.contactTitle {
  position: relative;
  width: 192px;
  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  margin-top: 40px;
  margin-left: 60px;
  margin-bottom: 30px;
}
.contactContent {
  display: flex;
  margin-left: 60px;
}
.contactBody {
  width:334px;
  height:92px;
  margin-right: 39px;
}
.contactmainContent {

  margin-top: 60px;
  width: 1200px;
  height:257px;
  background: rgba(255,255,255,0.7);
  box-shadow: inset 0px 6px 20px 0px rgba(255,255,255,0.5);
  border-radius: 12px;
  border: 1px solid #FFFFFF;
  backdrop-filter: blur(6px);
}
.contactway {
  width:100%;
  height:546px;
  background-image: url("../../assets/first-page/contactBgimg.webp")
}
.middle {
  width:524px;
  height:306px;
  margin-top: 25px;
}
.supportTitle {
  margin-bottom: 8px;
  width: 108px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
}
.supportBody {
  width: 320px;
  height: 104px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 80px;
}
.factorsupport {
  width: 100%;
  height:625px;
  //background-color: #00c1fe;
  background-color: #FAFBFF;
}
.supportcontent {
  margin-top: 60px;
  //background-color: #0fb803;
  display: flex;
}
.applydesc {
  width: 240px;
  height: 96px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  margin-bottom: 80px;
}
.applytitle {
  width: 96px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 20px;

}
.applycontent {
  margin-right: 79px;

}
.applycontainer {
  display: flex;
}
.recruitTitle {
  width: 108px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}
.recruitmain-body {
  width: 530px;
  height: 52px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 50px;
}
.recruiticon {
  margin-top: 2px;
  width: 20px;
  height: 20px;
  margin-right: 30px;
}
.recruitLi {
  display: flex;
}
.recruitRight {
  width:862px;
  height:358px;
  margin-left: 120px;
  margin-top: 60px;
}
.recruitBody {
  display: flex;
}
.recruitLeft {
  margin-top: 60px;
  width: 500px;
  height:400px;
  margin-bottom: 38px;
}
.recruit {
  margin:auto;
  width:100%;
  height:627px;
  //background-color: #00c1fe;
}
.applyConditionLi {
  margin-left: -600px;
  display: flex;
  width: 1200px;
  height: 22px;
  margin-bottom: 24px;
}
.sub-title {
  margin-left: 76px;
}
.icon {
  margin-right: 12px;
  width: 18px;
  height: 14px;
}
.applybody {
  width: 1200px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.applyheader {
  width:128px;
  height:68px;
  margin-left: -600px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.applyproName {
  width: 128px;
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 32px;
  color: #FFFFFF;
  line-height: 44px;
  margin-bottom: 18px;
}
.applyCondition {
  width: 100%;
  height: 428px;
  background-image: url("../../assets/first-page/applybg02.webp");
}
.rightTop-img {
  display: flex;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 50%;
  //margin:30px 476px 490px 40px;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */

  margin-left: 40px;
  margin-bottom: 20px;
}
.rightBottom-img {
  display: flex;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 50%;
  //margin:30px 476px 490px 40px;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin-top: 30px;
  margin-left: 40px;
  margin-bottom: 20px;
}
.rightTop-cnt {
  margin-left: 76px;
  margin-top: -30px;
  margin-bottom: 20px;
}
.leftBottom-cnt {
  margin-left: 76px;
  margin-top: -30px;
}
.contentFirst {
  display: flex;
}
.bottomless-title {
  width: 460px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 5px;
}
.bottommain-content {
  width: 460px;
  height: 78px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  text-align: left;
  font-style: normal;

}
.leftBottomTitle {
  width: 96px;
  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.scaleContent {
  width: 100%;
  display: flex;
  padding-top: 60px;
}
.scaleLeft {
  display: block;
  width: 580px;
  height:950px;
  margin-right: 40px;
}
.leftTop-img {
  display: flex;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 50%;
  //margin:30px 476px 490px 40px;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */

}
.leftTopTitle {
  width: 96px;
  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 30px;
}
.list {
  width: 30px;
  height: 25px;
}
.leftBottom-list {
  width: 30px;
  height: 25px;
  margin-left: 40px;
  margin-right: 12px;
  .list-content {
    width: 28px;
    height: 24px;


  }
}

.list-content {
  width: 24px;
  height: 28px;
  font-family: DINAlternate, DINAlternate;
  font-weight: bold;
  font-size: 24px;
  color: #BCC5D3;
  line-height: 28px;
  text-align: left;
  font-style: normal;

}
.sub-title {
  margin-left: 12px;
  width: 464px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 26px;
  font-style: normal;
  margin-bottom: 5px;
}
.main-content {
  width: 464px;
  height: 78px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  font-style: normal;
  margin-bottom: 20px;
  margin-left: 10px;
}
.cnt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.scaleRight {
  display: block;
  width:580px;
  height:950px;

}
.leftBottom-img {
  display: flex;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 50%;
  //margin:30px 476px 490px 40px;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin-top: 30px;
  margin-left: 40px;
  margin-bottom: 20px;

}

.content {
  width: 64px;
  height: 22px;
  font-weight: 600;
  font-size: 16px;
  color: #0077FF;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-left: 37px;

}
.goJump {
  width: 14px;
  height: 10px;
  margin-top: 20px;

}
.telIco{
  padding:0 0 0 26px;
  height: auto;
  overflow: hidden;
  position: relative;
}
.nameIco::before{
  content:"";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("../../assets/first-page/nameIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 2px;
}
.nameIco{
  padding:0 0 0 26px;
  height: auto;
  overflow: hidden;
  position: relative;
}
.organWidth {
  width: 100%;
  min-height: 100px;
  height: auto;
  overflow: hidden;
  background-color: #FAFAFA;
}
.applyRangeLi::before {
  content: "";
  display: block;
  width: 18px;
  height: 14px;
  background-image: url("../../assets/first-page/applyIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 5px;
}
.applyRangeLi {
  height: auto;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  color: #ffffff;
}

.applyRangeUl {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 60px;
}

.qmTxt {
  width: 250px;
  height: 130px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 26px;
  text-align: center;
  font-style: normal;
}
.qmName {
  width: 250px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: center;
  font-style: normal;
  margin-bottom: 16px;
}
.questionIco {
  margin: 0 75px 40px 75px;
  width: 100px;
  height: 100px;
  border-radius: 32px;
}
.questionIco img {
  margin: 0 auto 40px auto;
  width: 100px;
  height: 100px;
  //margin: 16px !important;
}
.leftTop {
  width: 580px;
  height: 584px;
  background: linear-gradient( 180deg, #FFFFFF 0%, #FFFFFF 100%);
  border-radius: 24px;
  padding-left: 40px;
  padding-top: 30px;
  margin-bottom: 40px;
}
.leftTop.expand {
  position:relative;
  height:fit-content !important;
  box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.06);
  margin-bottom: 0;
}
.leftTop.expand .main-content1 {
  height:68px;
  overflow:visible;
}
.leftTopContent {
  display: block;
}
.leftBottom {
  padding-top: 10px;
  width: 580px;
  height: 326px;
  background: linear-gradient( 180deg, #FFFFFF 0%, #FFFFFF 100%);
  border-radius: 24px;
}
.rightTop {
  padding-top: 30px;
  width: 580px;
  height: 455px;
  background: linear-gradient( 180deg, #FFFFFF 0%, #FFFFFF 100%);
  border-radius: 24px;
  margin-bottom: 40px;
}
.rightBottom {
  padding-top: 10px;
  width: 580px;
  height: 455px;
  background: linear-gradient( 180deg, #FFFFFF 0%, #FFFFFF 100%);
  //border-radius: 24px;
  border-radius: 24px;
}
.questionMes {


  height: auto;
  overflow: hidden;
}

.questionLi {
  flex-direction: column;
  display: block;
  width: 300px;
  height: auto;
  overflow: hidden;
  float: left;
  position: relative;
  margin-bottom: 60px;
}

.questionUl {
  width: 100%;
  height: auto;
  overflow: hidden;
}


.infoLink-first{
  color: #2164B1;
  line-height: 28px;
  margin-top:30px;
}
.infoLink-firstOne {
  color: #2164B1;
  line-height: 28px;

}
.infoLink-second {
  color: #2164B1;
  line-height: 28px;

}
.title {
  //display: block;
  width: 40px;
  height: 6px;
  display: flex;
  text-align: center;
}
* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  color: #000000;
  font-weight: normal;
}

.topBanner {
  height: 460px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
  background: url('@/assets/first-page/go302.webp') 0 0 / cover;
  padding-top: 161px;
}
h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 41px;
  color: #fff;
}



.go {
  display: none;
}

.projectInfo {
  display: flex;
  height: 572px;
  margin: 0 auto;

}
.projectInfo h2 {
  margin-top: 0;
}
.infoContent+.infoContent {
  margin-top: 12px;
}

h2 {
  color: #000000;
  line-height: 45px;
  margin: 0 0 18px;
  font-size: 32px;
  text-align: center;
}

h2+img {
  width: 40px;
  display: block;
  margin: 0 auto 40px;
}


.infoLink img {
  width: 14px;
  margin-right: 8px;
  vertical-align: middle;
}

.viewInfo:hover {
  border: 1px solid #288CFF;
  color: #288CFF;
}

.viewInfo {
  width: 128px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #0077FF;
  color: #0077FF;
  text-align: center;
  cursor: pointer;
}



.infoList h3 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  margin: auto;
}

.infoList h3:after {
  content: '';
  width: 0;
  overflow: hidden;
}

.infoList div div {
  margin: 10px 0 30px;
  display: inline-block;
}

.infoList div:last-of-type div {
  margin-bottom: 0px;
}

.questionInform h2,
.applyCondition h2 {
  text-align: center;
}

.applyCondition img {
  margin: 0 0 40px 0;
}

.targetContent {
  width: 600px;
  float: right;
}

.applyRange {
  width: 100%;
  height: 408px;
  background: url("@/assets/first-page/applyRange.webp") center no-repeat;
  background-size: cover;
}

.rangeContent {
  margin-top: 60px;
  /* display: flex; */
  /* justify-content: space-between; */
  color: #666666;
  margin-right: -39px;
}

.rangeContent>div {
  width: 374px;
  min-height: 487px;
  background: #fff;
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.06);
  float: left;
  margin-right: 39px;
  overflow: hidden;
  transition: all 0.3s linear;
}

.rangeContent>div:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 119, 255, 0.15);
  border: 1px solid #0077FF;
}

.rangeContent>div:hover .rangeTitle+div {
  -webkit-line-clamp: 30;
}

.rangeTitle+div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.rangeContent img {
  width: 374px;
  height: 280px;
}

.rangeContent img+div {
  padding: 24px;
}

.rangeTitle {
  color: #333333;
  line-height: 25px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
}

h3 {
  margin: 24px auto 14px;
  text-align: left;
  color: #333333;
  line-height: 25px;
  font-weight: bold;
  font-size:22px;
}

.applyCondition {
  //padding: 60px calc(50% - 600px) 0 calc(50% + 80px);
  /* background: url('@/assets/first-page/applyConditionL.png') 0 0 / 50% 100% no-repeat,
    url('@/assets/first-page/applyConditionR.png') 50% 0 / 100% 100% no-repeat; */
  height: 461px;
}
.applyCondition h2 {
  color: #fff;
  text-align: left;
}

.conditionText {
  margin-top: 58px;
  color: #FFFFFF;
  line-height: 26px;
  font-size: 16px;
  position: relative;
  width: 530px;
}

.conditionText::before {
  content: attr(data_index);
  width: 68px;
  line-height: 68px;
  background: #FFFFFF;
  color: #0077FF;
  font-size: 32px;
  border-radius: 50%;
  top: 50%;
  margin-top: -34px;
  position: absolute;
  text-align: center;
  left: -115px;
}

.conditionText:first-of-type {
  margin-bottom: 65px;
}

.conditionText:last-of-type {
  margin-top: 78px;
}

.relatedInfo {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  color: #666666;
  line-height: 22px;
  padding-bottom: 60px;
}

h3 {
  margin: 60px auto 30px;
  text-align: left;
  position: relative;
}

h3::after {
  content: ' ';
  position: absolute;
  width: 64px;
  height: 12px;
  background: url('@/assets/first-page/h3.png') 0 0 / cover;
  top: 50%;
  margin-top: -6px;
  margin-left: 14px;
}

.workFlowItems {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.workFlowItem img {
  width: 40px;
}

.workFlowItem span {
  font-size: 100px;
  color: #F3F3F3;
  line-height: 140px;
}

.workFlowItems>img {
  width: 63px;
  height: 10px;
  margin: 84px 55px auto 24px;
}

h4 {
  margin-bottom: 19px;
  color: #333333;
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
}

.applyDate>div {
  display: flex;
  align-items: flex-start;
}

.applyDate img {
  width: 32px;
  margin-right: 19px;
}

.applyDate h4 {
  margin-bottom: 8px;
}

.organStruct {
  margin-bottom: 60px;
}

.organStruct>div {
  display: flex;
  justify-content: space-between;
  color: #666666;
  line-height: 22px;
}

.organStruct h4 {
  margin-bottom: 18px;
}

.organStruct h4+div {
  margin-bottom: 6px;
}

.support {
  color: #333333;
  font-size: 16px;
  line-height: 22px;
}

.support h4 {
  margin-bottom: 30px;
}

.support>div {
  margin-bottom: 16px;
}

.applyConsult>div {
  display: flex;
}

.business,
.technique {
  width: 300px;
  height: 96px;
}

.widthMax {
  width: 380px;
}

.businessTel {
  display: flex;
  justify-content: space-between;
}

.applyConsult h4 {
  margin-bottom: 22px;
  min-height: 44px;
}

.business {
  margin-right: 96px;
}

.businessTel {
  color: #666666;
  line-height: 22px;
}

.applyConsult img {
  width: 18px;
  vertical-align: -3px;
  margin-right: 8px;
}

.mb14 {
  margin-bottom: 14px;
}
.proMes2 {
  padding-top: 16px;
  /* padding-bottom:30px; */
}
.footerModule {
  width: 100%;
  height: 80px;
  background: #33334F;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  line-height: 80px;
}

.imgWrapper {
  position:relative;
  overflow:visible !important;
}

.imgLeft {
  position:absolute;
  left: 0;
  top: -182px;
  width:194px;
  height:314px;
}

.imgRight {
  position:absolute;
  right: 0;
  top: -177px;
  width:299px;
  height:744px;
}

</style>

